
import { computed, defineComponent, ref } from 'vue'
import NewTariffSelectedContent from './NewTariffSelectedContent.vue'
import CurrentTariffContent from './CurrentTariffContent.vue'
import { useBillingStore } from '../../../../../store/stores/billing'
import { useProject } from '../../../../../hooks/useProject'
export default defineComponent({
    components: { NewTariffSelectedContent, CurrentTariffContent },
    props: {},
    setup(props, { emit }) {
        const billingStore = useBillingStore()
        const { project } = useProject()
        const currentTariff = computed(() => billingStore.getCurrentTariff(project.value.id))
        const isTrial = computed(() => billingStore.getCurrentTariff(project.value.id)?.isTrial)
        return { currentTariff, billingStore, isTrial }
    },
})
